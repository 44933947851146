import { uniqueId } from 'lodash'

import {
  GridActionsCellItem,
  GridToolbar,
  gridPageCountSelector,
  gridPageSelector,
  ptBR,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid'

import Box from '@mui/material/Box'
// import Button from '@mui/material/Button'
import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
// import VisibilityIcon from '@mui/icons-material/Visibility'

import * as mask from '../../utils/mask'

import * as styles from './styles'

function Table(props) {
  function CustomPagination() {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(_event, value) => apiRef.current.setPage(value - 1)}
      />
    )
  }

  const renderColumn = element => ({
    field: element.name,
    filterable: element.filter,
    renderHeader: () => (
      <Typography sx={styles.TextHeader}>{element.label}</Typography>
    ),
    width: element.width,
    hide: element.hide,
    editable: false,
    renderCell: params => (
      <Typography sx={styles.TextCell}>
        {element?.mask
          ? mask[element.mask](params.row[element.name])
          : params.row[element.name]}
      </Typography>
    )
  })

  function createColumns() {
    const cols = []
    for (let index = 0; index < props.columns.length; index++) {
      const element = props.columns[index]
      cols.push(renderColumn(element))
    }

    const aux = [
      {
        field: 'actions',
        type: 'actions',
        renderHeader: () => (
          <Typography sx={styles.TextHeader}>Ações</Typography>
        ),
        width: 120,
        editable: false,
        renderCell: params => [
          // <GridActionsCellItem
          //   key={uniqueId()}
          //   icon={<VisibilityIcon />}
          //   label="Mostrar"
          //   onClick={() => {
          //     props.setMethod('show')
          //     props.setFormData(params.row)
          //     props.setUuid(params.id)
          //   }}
          // />,
          <GridActionsCellItem
            key={uniqueId()}
            icon={<EditIcon />}
            label="Editar"
            onClick={() => {
              props.setMethod('edit')
              props.setFormData(params.row)
              props.setUuid(params.id)
            }}
          />,
          <GridActionsCellItem
            key={uniqueId()}
            icon={<DeleteIcon />}
            label="Deletar"
            onClick={() => {
              props.setMethod('delete')
              props.setUuid(params.id)
            }}
          />
        ]
      },
      ...cols
    ]

    return aux
  }

  return (
    <>
      <Box sx={styles.Box}>
        <Typography variant="h4">{props.title}</Typography>
      </Box>

      <styles.StripedDataGrid
        autoHeight
        density="compact"
        rows={props.rows}
        getRowId={row => row.uuid}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even'
        }
        columns={createColumns()}
        pageSize={10}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        components={{
          Toolbar: GridToolbar,
          Pagination: CustomPagination
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      />
    </>
  )
}

export default Table
