import React from 'react'
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles'

import { colors } from './core/stylesConfig'
import Routes from './routes'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

function App() {
  const styles = useTheme()
  const [theme, setTheme] = React.useState()

  React.useEffect(() => {
    if (styles) {
      const aux = createTheme({
        ...styles,
        palette: colors
      })

      setTheme(aux)
    }
  }, [])

  return (
    <>
      {theme ? (
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      ) : (
        <Routes />
      )}
    </>
  )
}

export default App
