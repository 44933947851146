// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
// import FmdGoodIcon from "@mui/icons-material/FmdGood"
// import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from '@mui/icons-material/Email'
import { Facebook, Instagram, Place, WhatsApp } from '@mui/icons-material'
export const dataSessoes = [
  {
    title: 'Inscrições',
    link: '#inscricoes'
  },
  {
    title: 'Premiações',
    link: '#premiacoes'
  },
  {
    title: 'Shows',
    link: '#shows'
  },
  {
    title: 'Informes',
    link: '#informes'
  },
  {
    title: 'Galeria',
    link: '#galeria'
  }
]

export const dataContatos = [
  {
    icon: <EmailIcon />,
    title: 'turismoecultura@caceres.mt.gov.br',
    link: 'mailto:turismoecultura@caceres.mt.gov.br'
  },
  {
    icon: <WhatsApp />,
    title: '+55 (65) 9 9958-1472',
    link: 'https://api.whatsapp.com/send?phone=556599581472&text=%F0%9F%8E%A3%20D%C3%9AVIDAS%20DO%2041%C2%BA%20FIPE%20SOMENTE%20PELO%20WHATSAPP%F0%9F%8E%A3'
  },
  {
    icon: <Facebook />,
    title: 'facebook.com/Fipecaceresoficial',
    link: 'https://www.facebook.com/Fipecaceresoficial/'
  },
  {
    icon: <Instagram />,
    title: 'instagram.com/fipecaceresoficial',
    link: 'https://www.instagram.com/fipecaceresoficial/'
  },
  {
    icon: <Place />,
    title: 'SICMATUR - Cavalhada I, Cáceres - MT',
    link: 'https://maps.app.goo.gl/RJos6otE8U3Y6pqP8'
  }
]
