import React from 'react'

import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import * as Components from '../../components'
import { varLocalStorage } from '../../core/configs'
import api from '../../services/apiRequest'

import * as Data from './data'
import * as styles from './styles'

const PainelSenior = () => {
  const [method, setMethod] = React.useState('table')
  const [uuid, setUuid] = React.useState('')
  const [config, setConfig] = React.useState(null)
  const [partNum, setPartNum] = React.useState('')
  const [prevPartNum, setPrevPartNum] = React.useState('')

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const [rows, setRows] = React.useState([])
  const [selectedRow, setSelectedRow] = React.useState(null)

  const theme = useTheme()

  const configToken = () => {
    const authData = JSON.parse(localStorage.getItem(varLocalStorage))
    if (authData) {
      setConfig({
        headers: {
          Autentication: authData.token
        }
      })
    } else {
      window.location.href = process.env.REACT_APP_API_URL_PAINEL + '/'
    }
  }

  const deleteData = async () => {
    setMethod('table')
    setTypeAlert('loading')

    try {
      await api.delete(`/withdrawal/${uuid}`, config)

      setMsg('Cadastro Deletado com Sucesso')
      setTypeAlert('success')
    } catch (err) {
      if (err?.response?.data?.detail[0]?.msg) {
        setMsg(err.response.data.detail[0].msg)
      } else {
        setMsg('Não foi possível concluir a Operação!')
      }
      setTypeAlert('error')
    }
  }

  const loadData = async () => {
    setTypeAlert('loading')
    setOpenAlert(true)

    let aux = []
    try {
      const resp = await api.get('/withdrawal/', {
        ...config,
        params: { all: true }
      })
      aux = resp.data
      setOpenAlert(false)
    } catch (err) {
      if (err?.message && err.message === 'Network Error') {
        setMsg(
          'Erro ao carregar os dados! Verifique a internet e tente novamente'
        )
      }
      setTypeAlert('error')
    }

    setRows(aux)
  }

  const handleSubmit = async () => {
    setMsg('')

    if (prevPartNum !== partNum) {
      setTypeAlert('loading')
      setOpenAlert(true)

      try {
        await api.put(
          `/withdrawal/${uuid}`,
          {
            participant_number: partNum
          },
          config
        )
        setMsg('Cadastro Atualizdo com Sucesso!')
        setTypeAlert('success')
      } catch (err) {
        console.log(err)
        if (err?.response?.data?.detail[0]?.msg) {
          setMsg(err.response.data.detail[0].msg)
        } else {
          setMsg('Não foi possível concluir a Operação!')
        }
        setTypeAlert('error')
      }
    }
  }

  React.useEffect(() => {
    if (method === 'table') {
      setSelectedRow(null)
    } else if (method === 'delete') {
      setMsg('Essa operação é Irreversível, deseja continuar?')
      setTypeAlert('delete')
      setOpenAlert(true)
    }
  }, [method])

  React.useEffect(() => {
    configToken()
    setMsg('')

    loadData()
  }, [])

  React.useEffect(() => {
    if (rows.length === 0 && config) {
      loadData()
    }
  }, [config])

  React.useEffect(() => {
    if (selectedRow) {
      setPrevPartNum(selectedRow.participant_number)
    }
  }, [selectedRow])

  return (
    <>
      <Components.Header />
      <Box sx={styles.Container}>
        <CssBaseline />
        {method === 'edit' ? (
          <>
            <Typography variant="h4" mb={2} color={theme.palette.primary.main}>
              Editar Número de Participante
            </Typography>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              label="Número de Participante"
              sx={{ display: 'flex', textAlign: 'center' }}
              value={partNum}
              onChange={e => setPartNum(e.target.value)}
              inputProps={{ maxLength: 20 }}
            />
          </>
        ) : (
          <Components.Table
            title={Data.localTable.title}
            rows={rows}
            columns={Data.localTable.columns}
            setMethod={setMethod}
            setFormData={setSelectedRow}
            setUuid={setUuid}
          />
        )}

        {method !== 'table' && method !== 'delete' && (
          <Stack spacing={5} direction="row-reverse">
            {method === 'show' && (
              <Button
                size="large"
                variant="contained"
                onClick={() => setMethod('edit')}
              >
                Editar
              </Button>
            )}
            <Button
              size="large"
              variant={method === 'show' ? 'outlined' : 'contained'}
              onClick={() =>
                method === 'show' ? setMethod('table') : handleSubmit()
              }
            >
              {method === 'show' ? 'Voltar' : 'Confirmar'}
            </Button>
            {method !== 'show' && (
              <Button
                size="large"
                variant="outlined"
                onClick={() => setMethod('table')}
              >
                Cancelar
              </Button>
            )}
          </Stack>
        )}
        <Box mb={2} />

        <Components.Alert
          open={openAlert}
          setOpen={setOpenAlert}
          alert={typeAlert}
          msg={msg}
          delete={deleteData}
        />
      </Box>
      <Components.Footer />
    </>
  )
}

export default PainelSenior
