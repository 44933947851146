import compareAsc from 'date-fns/compareAsc'
import parseISO from 'date-fns/parseISO'
import subYears from 'date-fns/subYears'

export const Cpf = value => {
  let resp = { err: false, value }
  value = value.replace(/[^0-9]/g, '')
  const aux = value.split('')
  if (aux.length === 0) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value: aux.join('')
    }
  } else {
    let d1 = 0
    for (let i = 0; i < 9; i++) {
      d1 += parseInt(aux[i]) * (10 - i)
    }
    d1 = d1 % 11
    if (d1 < 2) {
      d1 = 0
    } else {
      d1 = 11 - d1
    }
    let d2 = 0
    for (let i = 0; i < 10; i++) {
      d2 += parseInt(aux[i]) * (11 - i)
    }
    d2 = d2 % 11
    if (d2 < 2) {
      d2 = 0
    } else {
      d2 = 11 - d2
    }
    if (aux[9] !== d1.toString() || aux[10] !== d2.toString()) {
      resp = {
        err: true,
        msg: 'Cpf Inválido',
        value: aux.join('')
      }
    }
  }
  return resp
}

export const Phone = value => {
  let resp = { err: false, value }
  value = value.replace(/[^0-9]/g, '')
  if (value.split('').length < 3) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  } else {
    if (value.split('').length < 12) {
      resp = {
        err: true,
        msg: 'Número Inválido',
        value
      }
    }
  }
  return resp
}

export const Cep = value => {
  value = value.replace(/[^0-9]/g, '')
  let resp = { err: false, value }
  if (value.split('').length < 1) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  } else {
    if (value.split('').length < 8) {
      resp = {
        err: true,
        msg: 'Cep Inválido',
        value
      }
    }
  }
  return resp
}

export const Number = value => {
  let resp
  if (value.split.length > 0) {
    resp = {
      err: false,
      value
    }
  } else {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  }
  return resp
}

export const Required = value => {
  let resp = { err: false, value }
  if (value === '') {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  }
  return resp
}

export const Opcional = value => {
  const resp = { err: false, value }
  return resp
}

export const Date16 = value => {
  let resp = { err: false, value }
  let aux = value
    .toString()
    .replace(/[^0-9]/g, '')
    .split('')
  if (aux.length === 0) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  } else if (aux.length === 6) {
    resp = {
      err: true,
      msg: 'Formato esperado 01/01/2001',
      value
    }
  } else if (aux.length < 8) {
    resp = {
      err: true,
      msg: 'Formato de Data Inválido',
      value
    }
  } else {
    aux = `${aux[4]}${aux[5]}${aux[6]}${aux[7]}-${aux[2]}${aux[3]}-${aux[0]}${aux[1]}`
    const baseDate16 = subYears(new Date(2023, 3, 25), 16)
    const baseDate125 = subYears(new Date(2023, 3, 25), 125)
    const resp16 = compareAsc(baseDate16, parseISO(aux))
    const resp125 = compareAsc(parseISO(aux), baseDate125)
    if (resp16 !== 1 || resp125 !== 1) {
      resp = {
        err: true,
        msg: 'Pessoa fora da Idade Permitida',
        value
      }
    }
  }

  return resp
}

export const DateSenior = value => {
  let resp = { err: false, value }
  let aux = value
    .toString()
    .replace(/[^0-9]/g, '')
    .split('')
  if (aux.length === 0) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  } else if (aux.length === 6) {
    resp = {
      err: true,
      msg: 'Formato esperado 01/01/2001',
      value
    }
  } else if (aux.length < 8) {
    resp = {
      err: true,
      msg: 'Formato de Data Inválido',
      value
    }
  } else {
    aux = `${aux[4]}${aux[5]}${aux[6]}${aux[7]}-${aux[2]}${aux[3]}-${aux[0]}${aux[1]}`
    const baseDate16 = subYears(new Date(2023, 3, 25), 60)
    const baseDate125 = subYears(new Date(2023, 3, 25), 125)
    const resp16 = compareAsc(baseDate16, parseISO(aux))
    const resp125 = compareAsc(parseISO(aux), baseDate125)
    if (resp16 !== 1 || resp125 !== 1) {
      resp = {
        err: true,
        msg: 'Pessoa fora da Idade Permitida',
        value
      }
    }
  }

  return resp
}

export const DateInfantil = value => {
  let resp = { err: false, value }
  let aux = value
    .toString()
    .replace(/[^0-9]/g, '')
    .split('')
  if (aux.length === 0) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  } else if (aux.length === 6) {
    resp = {
      err: true,
      msg: 'Formato esperado 01/01/2001',
      value
    }
  } else if (aux.length < 8) {
    resp = {
      err: true,
      msg: 'Formato de Data Inválido',
      value
    }
  } else {
    aux = `${aux[4]}${aux[5]}${aux[6]}${aux[7]}-${aux[2]}${aux[3]}-${aux[0]}${aux[1]}`
    const baseDate8 = subYears(new Date(2023, 3, 25), 6)
    const baseDate12 = subYears(new Date(2023, 3, 25), 12)
    const resp8 = compareAsc(baseDate8, parseISO(aux))
    const resp12 = compareAsc(parseISO(aux), baseDate12)
    if (resp8 !== 1 || resp12 !== 1) {
      resp = {
        err: true,
        msg: 'Pessoa fora da Idade Permitida',
        value
      }
    }
  }
  return resp
}
