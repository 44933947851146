import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import * as styles from './styles'

const Index = () => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box sx={styles.headerMain}>
        <Box sx={styles.textBack}>
          <Typography variant="h5">Modalidade:</Typography>
          <Typography variant="h3" fontWeight="bold">
            Entrega de Kit
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Index
