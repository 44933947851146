import { BrowserRouter, Route, Routes } from 'react-router-dom'

import * as Pages from '../pages'
import { routes } from './data'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.login} element={<Pages.Login />} />
        <Route path={routes.home} element={<Pages.Home />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
