import { alpha, styled } from '@mui/material/styles'
import { DataGrid, gridClasses } from '@mui/x-data-grid'

export const Box = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px'
}

export const TextHeader = {
  fontWeight: 'bold'
}

export const TextCell = {}

export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  padding: 1,
  minHeight: '510px',
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: alpha(theme.palette.primary.light, 0.4),
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.light, 0.2),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
}))
