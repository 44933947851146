import React from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'

import api from '../../services/api'
import { varLocalStorage } from '../../core/configs'
import * as Components from '../../components'
import * as Functions from '../../utils/functions'
import { routes } from '../../routes/data'

import * as Data from './data'
import * as styles from './styles'

const Login = () => {
  const navigate = useNavigate()

  const [isSubmit, setIsSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState([])

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const handleChangeForm = (name, newValue) => {
    Functions.changeForm(formData, setFormData, name, newValue)
  }

  const handleSubmit = async () => {
    setMsg('')
    setIsSubmit(true)
    const validate = Functions.validateForm(Data.dataForm, formData)
    if (validate === false) {
      setTypeAlert('loading')
      setOpenAlert(true)
      try {
        const resp = await api.post('/login/', formData)
        localStorage.setItem(
          varLocalStorage,
          JSON.stringify({ token: resp.data.token })
        )
        navigate(routes.home)
      } catch (err) {
        console.log(err)
        if (err?.response?.data?.detail[0]?.msg) {
          setMsg(err.response.data.detail[0].msg)
        } else {
          setMsg('Não foi possível concluir a Operação!')
        }
        setTypeAlert('error')
      }
    }
  }

  return (
    <Box sx={styles.Container}>
      <CssBaseline />
      <Box sx={styles.Box}>
        <Typography variant="h3" gutterBottom>
          Login
        </Typography>

        <Typography variant="h6" sx={{ color: 'text.secondary', mb: 5 }}>
          Insira seus dados.
        </Typography>

        <Components.Form
          data={Data.dataForm}
          isSubmit={isSubmit}
          value={formData}
          onChange={handleChangeForm}
        />

        <Box>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
          >
            Login
          </Button>
        </Box>

        <Components.Alert
          open={openAlert}
          setOpen={setOpenAlert}
          alert={typeAlert}
          msg={msg}
          delete={() => {}}
        />
      </Box>
    </Box>
  )
}

export default Login
