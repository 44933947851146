import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import * as styles from './styles'
import { dataSessoes, dataContatos } from './data'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import riscLogo from '../../assets/img/Inovarisc.png'
import { ArrowRightAlt } from '@mui/icons-material'
import logo from '../../assets/img/41Fipe_logo.png'
const NewFooter = () => {
  return (
    <Box sx={styles.footerMain}>
      <Box
        sx={{
          height: { xs: '120px', lg: '300px' },
          width: { xs: '120px', lg: '280px' },
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <img style={styles.gif} alt="gif fipe" src={logo}></img>
      </Box>

      <Box sx={styles.sessoes}>
        <Typography
          marginBottom="20px"
          color="#fff"
          fontWeight="bold"
          variant="h6"
          sx={{ fontFamily: '"FIPe", sans-serif' }}
        >
          SESSÕES
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
          {dataSessoes.map((item, index) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  gap: '3px',
                  alignItems: 'center',
                  transition: '0.3s',
                  '&:hover': { color: '#00d084' }
                }}
                key={index}
                color="#fff"
              >
                <ArrowRightAlt />
                <Typography
                  variant="subtitle1"
                  onClick={() =>
                    window.open('https://fipe.caceres.mt.gov.br/' + item.link)
                  }
                  sx={{
                    cursor: 'pointer'
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Box>
      <Box sx={styles.contatos}>
        <Typography
          marginBottom="20px"
          color="#fff"
          fontWeight="bold"
          variant="h6"
          sx={{ fontFamily: '"FIPe", sans-serif' }}
        >
          CONTATOS
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          {dataContatos.map((item, index) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                  gap: '3px',
                  transition: '0.3s',
                  '&:hover': { color: '#00d084' }
                }}
                key={index}
                color="#fff"
                onClick={() => window.open(item.link)}
              >
                {item.icon}
                <Typography variant="subtitle2">{item.title}</Typography>
              </Box>
            )
          })}
        </Box>
      </Box>
      <Box sx={styles.riscBox}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography
            color="#fff"
            fontWeight="bold"
            variant="h6"
            sx={{ fontFamily: '"FIPe", sans-serif' }}
          >
            DESENVOLVIMENTO
          </Typography>
        </Box>
        <Box color="#fff">
          <FacebookIcon
            sx={{ minWidth: '40px', minHeight: '40px', cursor: 'pointer' }}
            onClick={() =>
              window.open('https://pt-br.facebook.com/risc.unemat.br/')
            }
          />
          <InstagramIcon
            sx={{ minWidth: '40px', minHeight: '40px', cursor: 'pointer' }}
            onClick={() =>
              window.open('https://www.instagram.com/risc.unemat.br/')
            }
          />
        </Box>
        <img
          src={riscLogo}
          style={{ maxWidth: '120px', marginTop: '20px' }}
          alt="riscLogo"
        ></img>
        <Typography
          sx={{ marginTop: '20px' }}
          marginBottom="20px"
          color="#fff"
          variant="subtitle2"
        >
          INOVARISC ©
        </Typography>
      </Box>
    </Box>
  )
}

export default NewFooter
