export const footerMain = {
  width: '100%',
  minHeight: '300px',
  background: '#24544F',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  gap: { xs: '20px', sm: '60px' },
  flexWrap: 'wrap',
  height: '100%',
  paddingTop: '10px'
}

export const gif = {
  width: '100%'
}

export const sessoes = {
  minWidth: '200px',
  minHeight: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: { md: 'flex-start', xs: 'center' },
  maxWidth: '270px'
}

export const contatos = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: { md: 'flex-start', xs: 'center' },
  maxWidth: '300px'
}

export const riscBox = {
  maxWidth: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}
