import * as validators from './validators'
import format from 'date-fns/format'

export const changeForm = (array, setArray, name, newValue) => {
  setArray({
    ...array,
    [name]: newValue
  })
}

export const showData = data => {
  const aux = []
  for (let i = 0; i < data.length; i++) {
    aux.push({
      ...data[i],
      type: 'TextShow',
      measures: {
        xs: 12,
        sm: 12
      }
    })
  }
  return aux
}

export const filterData = (form, value) => {
  let aux = {}

  for (let i = 0; i < form.length; i++) {
    aux = {
      ...aux,
      [form[i].name]: value[form[i].name]
    }
  }

  return aux
}

export const validateForm = (form, value) => {
  let temp = false
  for (let i = 0; i < form.length; i++) {
    if (value[form[i].name]) {
      temp = validators[form[i].validator](value[form[i].name]).err
      if (temp === true) break
    } else {
      temp = validators[form[i].validator]('').err
      if (temp === true) break
    }
  }
  return temp
}

export const formData = form => {
  const data = new FormData()
  const keys = Object.keys(form)

  for (let i = 0; i < keys.length; i++) {
    if (keys[i] !== 'subscription_number') {
      if (keys[i] === 'birth_date') {
        data.append(keys[i], sendDate(form[keys[i]]))
      } else if (keys[i] === 'zip_code') {
        data.append(keys[i], form[keys[i]].replace(/[^0-9]/g, ''))
      } else if (keys[i] === 'responsible_phone') {
        data.append(keys[i], form[keys[i]].replace(/[^0-9]/g, ''))
      } else {
        data.append(keys[i], form[keys[i]])
      }
    }
  }

  return data
}

export const sendData = (lastData, nextData) => {
  const keys = Object.keys(nextData)
  let aux = {}

  for (let i = 0; i < keys.length; i++) {
    if (nextData[keys[i]] !== lastData[keys[i]]) {
      aux = {
        ...aux,
        [keys[i]]: nextData[keys[i]].trim()
      }
    }
  }

  return aux
}

export const sendDate = value => {
  let temp = value.replace(/[^0-9]/g, '')
  temp = temp.split('')
  const aux = new Date(
    parseInt(`${temp[4]}${temp[5]}${temp[6]}${temp[7]}`),
    parseInt(`${temp[2]}${temp[3]}`) - 1,
    parseInt(`${temp[0]}${temp[1]}`)
  )

  return format(aux, 'yyyy-MM-dd')
}

export const maskDate = value => {
  let temp = value.replace(/[^0-9]/g, '')
  temp = temp.split('')
  const tempDate = new Date(
    parseInt(`${temp[0]}${temp[1]}${temp[2]}${temp[3]}`),
    parseInt(`${temp[4]}${temp[5]}`) - 1,
    parseInt(`${temp[6]}${temp[7]}`)
  )

  return format(tempDate, 'ddMMyyyy')
}
