// import background from '../assets/img/background.png'

export const Container = {
  display: 'flex',
  flexDirection: 'column',
  // backgroundImage: `url(${background})`,
  padding: 4
}

export const headerBox = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '30px'
}
