import { uniqueId } from 'lodash'

export const columns = [
  {
    key: uniqueId(),
    label: 'Uuid',
    name: 'uuid',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Nº de Inscrição',
    name: 'subscription_number',
    width: 150,
    filter: true,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'Nº de Participante',
    name: 'participant_number',
    width: 150,
    filter: true,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'Nome',
    name: 'owner_name',
    width: 250,
    filter: true,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'Modalidade',
    name: 'owner_modality',
    filter: true,
    width: 100,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'Nome do Responsável',
    name: 'withdrawal_responsible_name',
    width: 250,
    filter: true,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'CPF do Responsável',
    name: 'withdrawal_responsible_cpf',
    width: 200,
    filter: true,
    mask: 'Cpf',
    hide: false
  }
]

export const localTable = {
  title: 'Painel Kit',
  baseUrl: '/',
  columns
}
